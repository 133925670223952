import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: "/",
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'Home',
        component: () =>
            import ('@/views/Home.vue'),
        meta: {
            title: '帕马仕',
            keepAlive: true
        }
    },
    {
        path: '/company',
        name: 'company',
        component: () =>
            import ('@/views/company.vue'),
        meta: {
            title: '帕马仕',
            keepAlive: true
        }
    },
    {
        path: '/patent',
        name: 'patent',
        component: () =>
            import ('@/views/patent.vue'),
        meta: {
            title: '帕马仕',
            keepAlive: true
        }
    },
    {
        path: '/application',
        name: 'application',
        component: () =>
            import ('@/views/application.vue'),
        meta: {
            title: '帕马仕',
            keepAlive: true
        }
    },
    {
        path: '/web',
        name: 'web',
        component: () =>
            import ('@/views/web.vue'),
        meta: {
            title: '帕马仕',
            keepAlive: true
        }
    },
]

const router = new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes
    })
    //以下代码解决路由地址重复点击的报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

// // 新页面回到顶部
// router.afterEach((to, from, next) => {
//     window.scrollTo(0, 0)
// })

export default router