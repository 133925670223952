<template>
  <div id="app">
    <head-top></head-top>
    <router-view/>
  </div>
</template>
<script>
import headTop from './components/headTop.vue';
export default {
    data(){
        return{
            
        }
    },
    components:{
      headTop
    },
}
</script>
<style lang="scss">
#app{
  position: relative;
  padding-top: 120px;
  background: #F0F0FD;
}
*{
 ::-webkit-scrollbar {
    /*隐藏滚轮*/
    display: none;
  }
}
html, body {
    height: 100%;
}

body, ul, li, a, p, div {
    /*慎删*/
    padding: 0px;
    margin: 0px;
}
</style>
